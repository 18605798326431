<template>
  <div class="task-violations-list">
    <div
      v-for="violation in violationsList"
      :key="violation.id"
      class="task-violation">
      <div class="base-modal-info__item">
        <div class="base-modal-info__name">
          <span>Тип дефекта/нарушения</span>
        </div>
        <div class="base-modal-info__value">
          <p class="base-modal-info__value-txt">
            {{ violation.violation.name }}
          </p>
        </div>
      </div>

      <div
        v-for="field in violation.fields"
        :key="field.id"
        class="base-modal-info__item">
        <div class="base-modal-info__name">
          <span>{{field.field.name}}</span>
        </div>
        <div class="base-modal-info__value">
          <p class="base-modal-info__value-txt">
            {{ field.value }}
          </p>
        </div>
      </div>

      <div class="base-modal-info__item">
        <div class="base-modal-info__name">
          <span>Описание</span>
        </div>
        <div class="base-modal-info__value">
          <p class="base-modal-info__value-message">
            {{ violation.description }}
          </p>
        </div>
      </div>

      <div class="base-modal-info__item">
        <div class="base-modal-info__name">
          <span>Фото и видео</span>
        </div>
        <div class="base-modal-info__value">
          <files-list
            :files-list="violation.attachments"
            :max-length="6"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FilesList from "@/components/ServiceDesk/CommonComponents/FilesList/FilesList.vue";

export default {
  name: "ViolationsList",
  components: {FilesList},
  props: {
    violationsList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.task-violations-list {
  border-radius: 16px;
  border: 1px solid var(--border-main);
  background: var(--bg-main);
  padding: 24px 16px;
}
</style>