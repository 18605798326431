<template>
  <base-map
    :base-layers="baseLayers"
    :zoom="zoom"
    :center="center"
    :markers="networkObjectMarkers"
    @moveend="getObjects"
    @marker-click="params => this.$emit('network-object-marker-click', params)"
  />
</template>

<script>
import BaseMap from "@/components/BaseMap.vue";
import {mapState} from "vuex";
import Marker from "@/models/baseMap/Marker";
import Vue from "vue";
import PersonalListNetworkObjectMapMarker
  from "@/components/Telemetry/PersonalLists/PersonalListNetworkObjectMapMarker.vue";
import Icon from "@/models/baseMap/Icon";

export default {
  name: "TaskCreateMap",
  components: {BaseMap},
  props: {
    selectedObject: {
      required: true
    }
  },
  computed: {
    ...mapState({
      baseLayers: state => state.baseLayers.baseLayers,
      center: state => state.baseMap.center,
      zoom: state => state.baseMap.zoom,
      objectsList: state => state.technicalInspectionTasks.objectsList
    }),
    networkObjectMarkers() {
      const ObjectIconClass = Vue.extend(PersonalListNetworkObjectMapMarker);

      return this.objectsList.map(object => {
        const iconInstance = new ObjectIconClass();
        iconInstance.typeGroup = object._type.groupName;
        iconInstance.isActive = object.id === this.selectedObject;
        const icon = iconInstance.$mount();
        const iconHtml = icon.$el.outerHTML;

        const marker = new Marker({
          coords: object.coordinates,
          icon: new Icon({
            type: 'div_icon',
            size: [36, 42],
            anchor: [18, 21],
            html: iconHtml
          }),
          params: {
            objectId: object.id
          }
        })

        icon.$destroy();

        return marker;
      })
    }
  },
  methods: {
    getObjects({zoom, bounds}) {
      if (zoom >= process.env.VUE_APP_MIN_REQUEST_ZOOM) {
        this.$store.dispatch('technicalInspectionTasks/getObjectsList', {
          leftBounds: Object.values(bounds._southWest),
          rightBounds: Object.values(bounds._northEast)
        });
      }
    }
  }
}
</script>

<style scoped>

</style>