import { render, staticRenderFns } from "./ResourcesListPage.vue?vue&type=template&id=77d62ee4&scoped=true&"
import script from "./ResourcesListPage.vue?vue&type=script&lang=js&"
export * from "./ResourcesListPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77d62ee4",
  null
  
)

export default component.exports