import {TechnicalInspectionTaskState} from "@/models/TechnicalInspection/TechnicalInspectionTaskState";

export default class TechnicalInspectionTask {
    constructor(params) {
        this.id = params.id;
        this.state = new TechnicalInspectionTaskState(params.state.id, params.state.name);
        this.priority = params.priority;
        this.createdDate = params.created_date;
        this.plannedDate = params.planned_date;
        this.startedDate = params.started_date;
        this.finishedDate = params.finished_date;
        this.object = params.object;
        this.coordinates = params.object.coordinates.coordinates.reverse();
        this.objectType = params.object_type;
        this.author = params.author;
        this.resource = params.resource;
    }
}